<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Basic Buttons</div>
                    <div class="card-body text-center">
                        <div>
                            <p>Default</p>
                            <button class="btn btn-primary mr-2 mb-2">Primary</button>
                            <button class="btn btn-secondary mr-2 mb-2">Secondary</button>
                            <button class="btn btn-danger mr-2 mb-2">Danger</button>
                            <button class="btn btn-warning mr-2 mb-2">Warning</button>
                            <button class="btn btn-success mb-2">Success</button>
                        </div>
                        <div class="mt-4">
                            <p>Active</p>
                            <button class="btn btn-primary active mr-2 mb-2">Primary</button>
                            <button class="btn btn-secondary active mr-2 mb-2">Secondary</button>
                            <button class="btn btn-danger active mr-2 mb-2">Danger</button>
                            <button class="btn btn-warning active mr-2 mb-2">Warning</button>
                            <button class="btn btn-success active mb-2">Success</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Button Icons</div>
                    <div class="card-body text-center">
                        <div>
                            <p>Default</p>
                            <button class="btn btn-primary mr-2 mb-2"><fa icon="home" class="icon-push-right" /> Primary</button>
                            <button class="btn btn-secondary mr-2 mb-2"><fa icon="globe" class="icon-push-right" /> Secondary</button>
                            <button class="btn btn-danger mr-2 mb-2"><fa icon="exclamation-circle" class="icon-push-right" /> Danger</button>
                            <button class="btn btn-warning mr-2 mb-2"><fa icon="exclamation-triangle" class="icon-push-right" /> Warning</button>
                            <button class="btn btn-success mb-2"><fa icon="check" /> Success</button>
                        </div>
                        <div class="mt-4">
                            <button class="btn btn-primary mr-2 mb-2">Primary <fa icon="home" class="icon-push-left" /></button>
                            <button class="btn btn-secondary mr-2 mb-2">Secondary <fa icon="globe" class="icon-push-left" /></button>
                            <button class="btn btn-danger mr-2 mb-2">Danger <fa icon="exclamation-circle" class="icon-push-left" /></button>
                            <button class="btn btn-warning mr-2 mb-2">Warning <fa icon="exclamation-triangle" class="icon-push-left" /></button>
                            <button class="btn btn-success mb-2">Success <fa icon="check" class="icon-push-left" /></button>
                        </div>
                        <div class="mt-4">
                            <p>Active</p>
                            <button class="btn btn-primary active mr-2 mb-2"><fa icon="home" class="icon-push-right" /> Primary</button>
                            <button class="btn btn-secondary active mr-2 mb-2"><fa icon="globe" class="icon-push-right" /> Secondary</button>
                            <button class="btn btn-danger active mr-2 mb-2"><fa icon="exclamation-circle" class="icon-push-right" /> Danger</button>
                            <button class="btn btn-warning active mr-2 mb-2"><fa icon="exclamation-triangle" class="icon-push-right" /> Warning</button>
                            <button class="btn btn-success active mb-2"><fa icon="check" class="icon-push-right" /> Success</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Buttons Size</div>
                    <div class="card-body">
                        <div>
                            <p>Default</p>
                            <button class="btn btn-primary btn-sm mr-2 mb-2">Small</button>
                            <button class="btn btn-primary mr-2 mb-2">Default</button>
                            <button class="btn btn-primary btn-lg mb-2">Large</button>
                        </div>
                        <div class="mt-4">
                            <p>With Icons</p>
                            <button class="btn btn-primary btn-sm mr-2 mb-2"><fa icon="home" class="icon-push-right" />Small</button>
                            <button class="btn btn-primary mr-2 mb-2"><fa icon="home" class="icon-push-right" />Default</button>
                            <button class="btn btn-primary btn-lg mb-2"><fa icon="home" class="icon-push-right" />Large</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Buttons Rounded</div>
                    <div class="card-body">
                        <div>
                            <p>Default</p>
                            <button class="btn btn-primary btn-rounded btn-sm mr-2 mb-2">Primary</button>
                            <button class="btn btn-secondary btn-rounded btn-sm mr-2 mb-2">Secondary</button>
                            <button class="btn btn-danger btn-rounded btn-sm mr-2 mb-2">Danger</button>
                            <button class="btn btn-warning btn-rounded btn-sm mr-2 mb-2">Warning</button>
                            <button class="btn btn-success btn-rounded btn-sm mb-2">Success</button>
                        </div>
                        <div class="mt-4">
                            <p>With Icons</p>
                            <button class="btn btn-primary btn-rounded btn-sm mr-2 mb-2"><fa icon="home" class="icon-push-right" /> Primary</button>
                            <button class="btn btn-secondary btn-rounded btn-sm mr-2 mb-2"><fa icon="globe" class="icon-push-right" /> Secondary</button>
                            <button class="btn btn-danger btn-rounded btn-sm mr-2 mb-2"><fa icon="exclamation-circle" class="icon-push-right" /> Danger</button>
                            <button class="btn btn-warning btn-rounded btn-sm mr-2 mb-2"><fa icon="exclamation-triangle" class="icon-push-right" /> Warning</button>
                            <button class="btn btn-success btn-rounded btn-sm mb-2"><fa icon="check" class="icon-push-right" /> Success</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Buttons Groups</div>
                    <div class="card-body">
                        <div>
                            <p>Default</p>
                            <div class="btn-group flex-wrap">
                                <button class="btn btn-secondary btn-sm" type="button">Left</button>
                                <button class="btn btn-secondary btn-sm" type="button">Mid</button>
                                <button class="btn btn-secondary btn-sm" type="button">Right</button>
                            </div>
                        </div>
                        <div class="mt-4">
                            <p>Icons</p>
                            <div class="btn-group flex-wrap">
                                <button class="btn btn-secondary btn-icon btn-sm" type="button"><fa icon="chevron-circle-left" /></button>
                                <button class="btn btn-secondary btn-icon btn-sm" type="button"><fa icon="chevron-left" /></button>
                                <button class="btn btn-secondary btn-icon btn-sm" type="button"><fa icon="chevron-right" /></button>
                                <button class="btn btn-secondary btn-icon btn-sm" type="button"><fa icon="chevron-circle-right" /></button>
                            </div>
                        </div>
                        <div class="mt-4">
                            <p>Toolbar</p>
                            <div class="btn-toolbar">
                                <div class="btn-group flex-wrap mr-2">
                                    <button class="btn btn-secondary btn-sm" type="button">1</button>
                                    <button class="btn btn-secondary btn-sm" type="button">2</button>
                                    <button class="btn btn-secondary btn-sm" type="button">3</button>
                                    <button class="btn btn-secondary btn-sm" type="button">4</button>
                                </div>
                                <div class="btn-group">
                                    <button class="btn btn-secondary btn-sm btn-icon" type="button"><fa icon="chevron-left" /></button>
                                    <button class="btn btn-secondary btn-sm btn-icon" type="button"><fa icon="chevron-right" /></button>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <p>Vertical</p>
                            <div class="btn-toolbar">
                                <div class="btn-group-vertical mr-2 mb-2">
                                    <button class="btn btn-secondary btn-sm" type="button">Top</button>
                                    <button class="btn btn-secondary btn-sm" type="button">Middle</button>
                                    <button class="btn btn-secondary btn-sm" type="button">Bottom</button>
                                </div>
                                <div class="btn-group-vertical mr-2 mb-2">
                                    <button class="btn btn-primary btn-sm" type="button">Top</button>
                                    <button class="btn btn-primary btn-sm" type="button">Middle</button>
                                    <button class="btn btn-primary btn-sm" type="button">Bottom</button>
                                </div>
                                <div class="btn-group-vertical mr-2 mb-2">
                                    <button class="btn btn-danger btn-sm" type="button">Top</button>
                                    <button class="btn btn-danger btn-sm" type="button">Middle</button>
                                    <button class="btn btn-danger btn-sm" type="button">Bottom</button>
                                </div>
                                <div class="btn-group-vertical mr-2 mb-2">
                                    <button class="btn btn-warning btn-sm" type="button">Top</button>
                                    <button class="btn btn-warning btn-sm" type="button">Middle</button>
                                    <button class="btn btn-warning btn-sm" type="button">Bottom</button>
                                </div>
                                <div class="btn-group-vertical mr-2 mb-2">
                                    <button class="btn btn-success btn-sm" type="button">Top</button>
                                    <button class="btn btn-success btn-sm" type="button">Middle</button>
                                    <button class="btn btn-success btn-sm" type="button">Bottom</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Buttons Outline</div>
                    <div class="card-body text-center">
                        <div>
                            <button class="btn btn-outline-primary mr-2 mb-2">Primary</button>
                            <button class="btn btn-outline-secondary mr-2 mb-2">Secondary</button>
                            <button class="btn btn-outline-danger mr-2 mb-2">Danger</button>
                            <button class="btn btn-outline-warning mr-2 mb-2">Warning</button>
                            <button class="btn btn-outline-success mb-2">Success</button>
                        </div>
                        <div>
                            <button class="btn btn-outline-primary mr-2 mb-2"><fa icon="home" class="icon-push-right" /> Primary</button>
                            <button class="btn btn-outline-secondary mr-2 mb-2"><fa icon="globe" class="icon-push-right" /> Secondary</button>
                            <button class="btn btn-outline-danger mr-2 mb-2"><fa icon="exclamation-circle" class="icon-push-right" /> Danger</button>
                            <button class="btn btn-outline-warning mr-2 mb-2"><fa icon="exclamation-triangle" class="icon-push-right" /> Warning</button>
                            <button class="btn btn-outline-success mb-2"><fa icon="check" class="icon-push-right" /> Success</button>
                        </div>
                        <div class="mt-4">
                            <button class="btn btn-rounded btn-outline-primary mr-2 mb-2">Primary</button>
                            <button class="btn btn-rounded btn-outline-secondary mr-2 mb-2">Secondary</button>
                            <button class="btn btn-rounded btn-outline-danger mr-2 mb-2">Danger</button>
                            <button class="btn btn-rounded btn-outline-warning mr-2 mb-2">Warning</button>
                            <button class="btn btn-rounded btn-outline-success mb-2">Success</button>
                        </div>
                        <div>
                            <button class="btn btn-outline-primary btn-rounded mr-2 mb-2"><fa icon="home" class="icon-push-right" /> Primary</button>
                            <button class="btn btn-outline-secondary btn-rounded mr-2 mb-2"><fa icon="globe" class="icon-push-right" /> Secondary</button>
                            <button class="btn btn-outline-danger btn-rounded mr-2 mb-2"><fa icon="exclamation-circle" class="icon-push-right" /> Danger</button>
                            <button class="btn btn-outline-warning btn-rounded mr-2 mb-2"><fa icon="exclamation-triangle" class="icon-push-right" /> Warning</button>
                            <button class="btn btn-outline-success btn-rounded mb-2"><fa icon="check" class="icon-push-right" /> Success</button>
                        </div>
                        <div class="mt-4">
                            <button class="btn btn-rounded btn-sm btn-outline-primary mr-2 mb-2">Primary</button>
                            <button class="btn btn-rounded btn-outline-danger mr-2 mb-2">Danger</button>
                            <button class="btn btn-rounded btn-lg btn-outline-success mb-2">Success</button>
                        </div>
                        <div>
                            <button class="btn btn-outline-primary btn-rounded btn-sm mr-2 mb-2"><fa icon="home" class="icon-push-right" /> Primary</button>
                            <button class="btn btn-outline-danger btn-rounded mr-2 mb-2"><fa icon="exclamation-circle" class="icon-push-right" /> Danger</button>
                            <button class="btn btn-outline-success btn-rounded btn-lg mb-2"><fa icon="check" class="icon-push-right" /> Success</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header card-header-divider">Buttons Social</div>
                    <div class="card-body">
                        <div>
                            <social-button type="facebook" label="Facebook" icon="facebook-f" />
                        </div>
                        <div class="mt-4">
                            <social-button type="google" label="Google" icon="google" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SocialButton from "../../components/SocialButton";

    export default {
        name: 'Buttons',
        components: {
            SocialButton,
        }
    }
</script>
